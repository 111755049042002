import "./ContentBlock.scss";

const ContentBlock = ({ title, text, color, stacks, link }) => {
	return (
		<article className='content-block'>
			<h2
				className='content-block__textbox-title'
				style={{ color: color }}>
				{title}
			</h2>
			<p className='content-block__textbox-text'>{text}</p>
			<section className='content-block__stack'>
                {stacks.map(stack=>{
                    return <img
					src={stack}
					className='content-block__stack-image'
					alt={`${stack} icon`}
				/>})}
			</section>
			<section className='content-block__action'>
				<a
					href={link}
					target='_blank'
					rel='noreferrer'
					className='content-block__action-button'>
					GitHub
				</a>
			</section>
		</article>
	);
};

export default ContentBlock;
