import "./PageNav.scss";

const PageNav = ({ pageNav, section }) => {
	return (
		<nav className='PageNav'>
			<div className='PageNav__logo' onClick={() => pageNav("hero")}>
				<h1 className='PageNav__logo-text'>NT</h1>
			</div>
			<button
				onClick={() => pageNav("about")}
				className='PageNav__button'>
				<p
					className={
						section === "about"
							? "PageNav__button-text"
							: "PageNav__button-text PageNav__button-text--disabled"
					}>
					About
				</p>
				<span
					className={
						section === "about"
							? "PageNav__button-border PageNav__button-border--highlight"
							: "PageNav__button-border"
					}></span>
			</button>
			<button
				onClick={() => pageNav("stack")}
				className='PageNav__button'>
				<p
					className={
						section === "stack"
							? "PageNav__button-text"
							: "PageNav__button-text PageNav__button-text--disabled"
					}>
					Technology
				</p>
				<span
					className={
						section === "stack"
							? "PageNav__button-border PageNav__button-border--highlight"
							: "PageNav__button-border"
					}></span>
			</button>
			<button
				onClick={() => pageNav("content")}
				className='PageNav__button'>
				<p
					className={
						section === "content"
							? "PageNav__button-text"
							: "PageNav__button-text PageNav__button-text--disabled"
					}>
					Project
				</p>
				<span
					className={
						section === "content"
							? "PageNav__button-border PageNav__button-border--highlight"
							: "PageNav__button-border"
					}></span>
			</button>
		</nav>
	);
};

export default PageNav;
