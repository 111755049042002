import React from "react";
import "./PageContent.scss";
import ContentBlock from "../ContentBlock/ContentBlock";
import ImageSlider from "../ImageSlider/ImageSlider";
import epicEatScreen from "../../assets/images/epic-eat.png";
import epicEatScreen2 from "../../assets/images/epic-eat-2.png";
import epicEatScreen3 from "../../assets/images/epic-eat-3.png";
import finScreen from "../../assets/images/fin.png";
import finScreen2 from "../../assets/images/fin-2.png";
import finScreen3 from "../../assets/images/fin-3.png";
import inStockScreen from "../../assets/images/in-stock.png";
import inStockScreen2 from "../../assets/images/in-stock-2.png";
import inStockScreen3 from "../../assets/images/in-stock-3.png";
import chatroomScreen from "../../assets/images/chat-room.png";
import chatroomScreen2 from "../../assets/images/chat-room-2.png";
import django from "../../assets/icons/django.svg";
import express from "../../assets/icons/express.svg";
import js from "../../assets/icons/js.svg";
import mongo from "../../assets/icons/mongo.svg";
import sqlite from "../../assets/icons/sqlite.svg";
import node from "../../assets/icons/node.svg";
import python from "../../assets/icons/python.svg";
import react from "../../assets/icons/react.svg";
import sass from "../../assets/icons/sass.svg";

const PageContent = (_props, ref) => {
	return (
		<section className='page-content' ref={ref}>
			<h2 className='page-content__header'>Project</h2>
			<ImageSlider
				images={[epicEatScreen, epicEatScreen2, epicEatScreen3]}
				alt='epic eat screen'
				mobile={true}
				color='#F5C551'
				toColor='#FEDC8C'
			/>
			<ContentBlock
				title='Epic Eat'
				text='Location based app that generates personalized restaurant recommendations for users.'
				color='#F5C551'
				stacks={[js, react, mongo, node]}
				link='https://github.com/nathanieltse/Epic-Eat'
			/>
			<ImageSlider
				images={[finScreen,finScreen2 ,finScreen3]}
				alt='fin screen'
				color='#264D5E'
				toColor='#10506C'
			/>
			<ContentBlock
				title='Fin'
				text='Budget planning tool aim to help user keep track of their spendings, built with bootstrap component library.'
				color='#3582A4'
				stacks={[js, python, django, sqlite]}
				link='https://github.com/nathanieltse/Fin'
			/>
			<ImageSlider
				images={[inStockScreen, inStockScreen2, inStockScreen3]}
				alt='In stock screen'
				color='#24293E'
				toColor='#3E68DE'
			/>
			<ContentBlock
				title='InStock'
				text='Warehouse management system built to manage inventory and warehouse locations'
				color='#3E68DE'
				stacks={[react, express, node, sass]}
				link='https://github.com/nathanieltse/InStock'
			/>
			<ImageSlider
				images={[chatroomScreen, chatroomScreen2]}
				alt='chat room screen'
				mobile={true}
				color='#455A59'
				toColor='#3D7572'
			/>
			<ContentBlock
				title='Chat room'
				text="24 hours hackathon project, real time chat room app that's built on socket.io"
				color='#4F827F'
				stacks={[react, express, node, sass]}
				link='https://github.com/nathanieltse/Message-app'
			/>
		</section>
	);
};
const forwardedPageContent = React.forwardRef(PageContent);

export default forwardedPageContent;
