import PageHero from "../../components/PageHero/PageHero";
import PageContent from "../../components/PageContent/PageContent";
import PageStack from "../../components/PageStack/PageStack";
import PageAbout from "../../components/PageAbout/PageAbout";
import PageNav from "../../components/PageHeader/PageNav.jsx";
import { useRef, useState } from "react";

import "./HomePage.scss";
const HomePage = () => {
	const page = useRef(null);
	const hero = useRef(null);
	const about = useRef(null);
	const stack = useRef(null);
	const content = useRef(null);
	const [section, setSection] = useState("hero");
	const [scrollPosition, setScrollPosition] = useState(0);

	const pageNav = (scrollRef) => {
		if (scrollRef === "hero") {
			setSection("hero");
			page.current.scrollTo({
				top: hero.current.offsetTop,
				behavior: "smooth",
			});
		}
		if (scrollRef === "about") {
			setSection("about");
			page.current.scrollTo({
				top: about.current.offsetTop,
				behavior: "smooth",
			});
		}
		if (scrollRef === "stack") {
			setSection("stack");
			page.current.scrollTo({
				top: stack.current.offsetTop,
				behavior: "smooth",
			});
		}
		if (scrollRef === "content") {
			setSection("content");
			page.current.scrollTo({
				top: content.current.offsetTop,
				behavior: "smooth",
			});
		}
	};

	const pageScroll = () => {
		setScrollPosition(page.current.scrollTop);
		if (
			section === "hero" &&
			page.current.scrollTop > scrollPosition &&
			page.current.scrollTop > hero.current.offsetTop + 300
		) {
			setSection("about");
		} else if (
			section === "about" &&
			page.current.scrollTop < scrollPosition &&
			page.current.scrollTop < about.current.offsetTop - 300
		) {
			setSection("hero");
		} else if (
			section === "about" &&
			page.current.scrollTop > scrollPosition &&
			page.current.scrollTop > about.current.offsetTop + 300
		) {
			setSection("stack");
		} else if (
			section === "stack" &&
			page.current.scrollTop < scrollPosition &&
			page.current.scrollTop < stack.current.offsetTop - 300
		) {
			setSection("about");
		} else if (
			section === "stack" &&
			page.current.scrollTop > scrollPosition &&
			page.current.scrollTop > stack.current.offsetTop + 300
		) {
			setSection("content");
		} else if (
			section === "content" &&
			page.current.scrollTop < scrollPosition &&
			page.current.scrollTop < content.current.offsetTop - 300
		) {
			setSection("stack");
		}
	};

	return (
		<div id='main' className='home-page' ref={page} onScroll={pageScroll}>
			<PageNav pageNav={pageNav} section={section} />
			<section className='home-page__wrapper'>
				<PageHero ref={hero} />
				<PageAbout ref={about} />
				<PageStack ref={stack} />
				<PageContent ref={content} />
			</section>
		</div>
	);
};

export default HomePage;
