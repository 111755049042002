import { useEffect, useState } from 'react'
import React from 'react'
import linkedIn from '../../assets/icons/linkedin.svg'
import github from '../../assets/icons/github.svg'
import email from '../../assets/icons/email.svg'
import './PageHero.scss'

const reasons = [' I like creative solutions.',' I like creating beautiful and thoughtful UI.', ' I am passionate about creative problem solving.',' I enjoy learning new and cool things.', ' I am always up for a good challenge.', ' I enjoy working with people that inspire me.']
const PageHero = (_props, ref) =>  {
    const [index, setIndex] = useState(0)
    const [showWords, setShowWords] = useState('')

    useEffect(() => {
        const displayWords = () => {
            let combineWordArr = []

            reasons[index].split('').forEach((word, i) => {
                setTimeout(() => {
                    combineWordArr.push(word)
                    setShowWords(combineWordArr.join(''))
                }, 70*i)
            })
            
            setTimeout(()=>{
                combineWordArr.forEach((word, i) => {
                    setTimeout(()=>{
                        combineWordArr.splice(combineWordArr.length-1, 1)
                        setShowWords(combineWordArr.join(''))
                    },50*i)
                })
            },5000)
            
            setTimeout(() => {
                if ( index === 5){
                    setIndex(0)
                } else{
                    setIndex(index+1)
                }
                combineWordArr=[]
                return setShowWords("")
            }, 7500);
        }

        displayWords()
    },[index])

    
        return (
            <section className="PageHero" ref={ref}>
                    <h2 className="PageHero__hero">Hi👋🏼, My name is Nathaniel.</h2>
                    <h3 className="PageHero__location">Toronto, Ontario</h3>
                    <h3 className="PageHero__title">Full Stack Developer |  UX/UI design</h3>
                    <h3 className="PageHero__text">I do what I do because {showWords}<span className="PageHero__text-bar">.</span></h3>
                    <section className="PageHero__action">
                        <a href="https://www.linkedin.com/in/nathanieltse/" target="_blank" rel="noreferrer">
                            <img className="PageHero__action-icon" src={linkedIn} alt="linkedIn icon"/>
                        </a>
                        <a href="https://github.com/nathanieltse" target="_blank" rel="noreferrer">
                            <img className="PageHero__action-icon" src={github} alt="github icon"/>
                        </a>
                        <a href="mailto:nathanieltse@hotmail.com" target="_blank" rel="noreferrer">
                            <img className="PageHero__action-icon" src={email} alt="email icon"/>
                        </a>
                    </section>
            </section>
        ) 
}

const forwardedPageHero = React.forwardRef(PageHero)

export default forwardedPageHero