import React from "react";
import profile from "../../assets/images/profile.jpg";
import "./PageAbout.scss";

const PageAbout = (_props, ref) => {
	return (
		<section className='PageAbout' ref={ref}>
			<div className='PageAbout__avatar'>
				<img
					className='PageAbout__image'
					src={profile}
					alt='Nathaniel tse'
				/>
			</div>
			<div className='PageAbout__text'>
				<p className='PageAbout__paragraph'>
					👋🏼 My name is Nathaniel, people usually call me Nate.
				</p>
				<p className='PageAbout__paragraph'>
					🚀 I believe technology is the future of connecting people
					together and thoughtfully designed products help elevate
					solutions for problems.
				</p>
				<p className='PageAbout__paragraph'>
					🧑🏻‍💻 I am a full stack developer who are passionate and
					skills in UX/UI design.
				</p>
				<p className='PageAbout__paragraph'>
					🌱 I am currently working at 7shifts where I help build team
					management tool for the restaurant industry.
				</p>
				<p className='PageAbout__paragraph'>
					👉🏻 I love what I do because it allows me to combine what
					motivates me and my experiences together - building
					elegantly designed products, solving complex problems and
					connecting people together through technology.
				</p>
			</div>
		</section>
	);
};

const forwardedPageAbout = React.forwardRef(PageAbout);

export default forwardedPageAbout;
