import React from "react";
import django from "../../assets/icons/django.svg";
import express from "../../assets/icons/express.svg";
import js from "../../assets/icons/js.svg";
import mongo from "../../assets/icons/mongo.svg";
import sqlite from "../../assets/icons/sqlite.svg";
import node from "../../assets/icons/node.svg";
import python from "../../assets/icons/python.svg";
import react from "../../assets/icons/react.svg";
import sass from "../../assets/icons/sass.svg";
import html from "../../assets/icons/html.svg";
import mysql from "../../assets/icons/mysql.svg";
import css from "../../assets/icons/css.svg";
import jquery from "../../assets/icons/jquery.svg";
import jest from "../../assets/icons/jest.svg";
import php from "../../assets/icons/php.svg";
import redux from "../../assets/icons/redux.svg";
import apollo from "../../assets/icons/apollo.svg";
import typeScript from "../../assets/icons/typeScript.svg";
import "./PageStack.scss";
import StackScrollBar from "../StackScrollBar/StackScrollBar";

const PageStack = (_props, ref) => {
	return (
		<section className='PageStack' ref={ref}>
			<h2 className='PageStack__title'>Technology</h2>
			<StackScrollBar
				title='Frontend'
				images={[
					js,
					typeScript,
					react,
					jest,
					redux,
					apollo,
					sass,
					jquery,
					html,
					css,
				]}
			/>
			<StackScrollBar
				title='Backend'
				images={[node, express, php, python, django]}
			/>
			<StackScrollBar title='Database' images={[sqlite, mysql, mongo]} />
		</section>
	);
};

const forwardedPageStack = React.forwardRef(PageStack);

export default forwardedPageStack;
