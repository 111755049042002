import "./StackScrollBar.scss";

const StackScrollBar = ({ title, images }) => {
	return (
		<section className='stack-scroll-bar'>
			<p className='stack-scroll-bar__header'>{title}</p>
			<section className='stack-scroll-bar__scroll-bar'>
				<div className='stack-scroll-bar__scroll-bar-wrapper'>
					{images.map((image,i) => {
						return (
							<img
                                key={i}
								src={image}
								className='stack-scroll-bar__image'
								alt='stack icon'
							/>
						);
					})}
				</div>
			</section>
		</section>
	);
};

export default StackScrollBar;
