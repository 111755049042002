import "./ImageSlider.scss";
import arrow_left from "../../assets/icons/arrow-left.svg";
import arrow_right from "../../assets/icons/arrow-right.svg";
import { useState } from "react";

const ImageSlider = ({ images, alt, mobile, color, toColor }) => {
	const [index, setIndex] = useState(0);

	return (
		<section className='image-slider'>
			<div
				className='image-slider__background'
				style={{
					background: `linear-gradient(-225deg, ${color} 0%, ${toColor} 100%)`,
					backgroundSize: `400% 400%`,
				}}></div>
			<section
				className='image-slider__body'
				style={{
					transform: `translateX(${-100 * index}%)`,
					transition: `0.8s`,
				}}>
				{images.map((image, i) => {
					return (
						<div key={i} className='image-slider__body-container'>
							<img
								className={
									mobile
										? "image-slider__image--mobile"
										: "image-slider__image"
								}
								src={image}
								alt={alt}
							/>
						</div>
					);
				})}
			</section>
			{index && (
				<button
					className='image-slider__button image-slider__button--left'
					onClick={() => setIndex(index - 1)}>
					<img
						className='image-slider__button-icon'
						src={arrow_left}
						alt='arrow icon'
					/>
				</button>
			)}
			{index < images.length - 1 && (
				<button
					className='image-slider__button image-slider__button--right'
					onClick={() => setIndex(index + 1)}>
					<img
						className='image-slider__button-icon'
						src={arrow_right}
						alt='arrow icon'
					/>
				</button>
			)}
			<section className='image-slider__index'>
				{images.map((image, i) => {
					if (index === i) {
						return (
							<div
								key={i}
								className='image-slider__index-dot image-slider__index-dot--active'></div>
						);
					}
					return (
						<div key={i} className='image-slider__index-dot'></div>
					);
				})}
			</section>
		</section>
	);
};

export default ImageSlider;
